import React, { useState } from "react";
import logo from '../../assets/logo/logo1.png'

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [logInUser, setLogInUser] = useState(false);

  return (
    <nav className="fixed top-0 left-0 w-full bg-white shadow z-10">
      <div className="container max-w-screen-2xl px-6 py-3 lg:px-16  xl:px-24 2xl:px-[80px]  mx-auto">
        <div className="lg:flex lg:items-center lg:justify-between">

{/*         <img className="w-auto h-6 sm:h-16" src={logo} alt="" /> */}
          <div className="flex items-center justify-between">
            <a href="#">
               <img className="h-10 md:h-14 lg:h-20 w-auto" src={logo} alt="" />
            </a>

            {/* Mobile menu button */}
            <div className="flex lg:hidden">
              <button
                onClick={() => setIsOpen(!isOpen)}
                type="button"
                className="text-gray-500 hover:text-gray-600 focus:outline-none focus:text-gray-600"
                aria-label="toggle menu"
              >
                {!isOpen ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4 8h16M4 16h16"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                )}
              </button>
            </div>
          </div>

          {/* Mobile Menu open: "block", Menu closed: "hidden" */}
          <div
            className={`absolute shadow-md lg:shadow-none inset-x-0 z-20 w-full px-6 pt-4 pb-8 transition-all duration-300 ease-in-out bg-white lg:mt-0 lg:p-0 lg:top-0 lg:relative lg:w-auto lg:opacity-100 lg:translate-x-0 lg:flex lg:items-center ${
              isOpen
                ? "translate-x-0 opacity-100"
                : "opacity-0 -translate-x-full"
            }`}
          >
            <div className="flex flex-col -mx-6 lg:flex-row lg:items-center lg:mx-8">
              <a
                href="#"
                 className="px-3 py-2 mx-3 mt-2  text-primaryC/95 transition-colors duration-300 transform rounded-md lg:mt-0 hover:text-primaryC"
              >
                Home
              </a>
              <a
                href="#"
                 className="px-3 py-2 mx-3 mt-2  text-primaryC/95 transition-colors duration-300 transform rounded-md lg:mt-0 hover:text-primaryC"
              >
                Services
              </a>
              <a
                href="#"
                className="px-3 py-2 mx-3 mt-2  text-primaryC/95 transition-colors duration-300 transform rounded-md lg:mt-0 hover:text-primaryC"
              >
                Portfolios
              </a>
              <a
                href="#"
                className="px-3 py-2 mx-3 mt-2  text-primaryC/95 transition-colors duration-300 transform rounded-md lg:mt-0 hover:text-primaryC"
              >
                Products
              </a>
            </div>
          </div>

          <div className=" mt-4 hidden lg:mt-0 lg:flex gap-x-4">
            <button>
              <a
                className="content_btn inline-block uppercase border border-primaryC  text-whiteC  transition duration-300 delay-100 ease-in-out hover:bg-primaryC  "
                href="#"
              >
                contact us
              </a>
            </button>
          </div>


        </div>
      </div>
    </nav>
  );
};

export default Header;
