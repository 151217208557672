import React from 'react';
import ImageSwiper from './ImageSwiper';
import './Slider.css';



export const Hero = () => {
  return (
    <section className='flex flex-col justify-center items-center mx-auto max-w-[1440px] '>
      <div className='flex flex-col px-[12px] py-[32px] md:px-[32px] md:pt-[24px] lg:px-[64px] mt-16 lg:mt-16 lg:pt-[48px] 2xl:px-[80px] 2xl:pt-[64px] '>


          <div className='flex flex-col gap-y-[10px] items-center tracking-widest md:flex-row md:justify-center lg:justify-between md:gap-[12px] lg:gap-[16px] 2xl:gap-[32px]'>
            <div className=''>            
              <h1 className='animation flex text-[40px] font-bold text-primaryC uppercase md:text-[64px] lg:text-[92px] 2xl:text-[128px]'>building <span className='hidden xs:block pl-[8px] md:hidden'>your</span></h1>
            </div>
            <div className='order-last'>
              <h1 className='text-[40px] font-bold text-primaryC uppercase xs:hidden md:text-[64px] md:block lg:text-[92px] 2xl:text-[128px]'>your</h1>
              <h1 className='text-[32px] overline decoration-gray-400 font-semibold pt-3 text-primaryC uppercase hidden xs:block md:hidden'>Dream</h1>
            </div>
            <div data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="2000"  className='min-w-[100px]'>
              <img src="HH.jpg" alt="Hero_Img" className='h-[56px] w-[120px]  rounded-full md:h-[66px] md:w-[156px] lg:h-[90px] lg:w-[220px] 2xl:h-[118px] 2xl:w-[257px] '/>
            </div>
          </div>

          <div className='flex flex-col gap-y-[10px] items-center md:gap-[12px] lg:flex-row lg:gap-[16px] 2xl:gap-[32px] lg:max-w-[1024px] 2xl:max-w-[1440px] '>
            <div className='order-last lg:-order-none '>
              <p className='text-[12px] font-normal text-primaryC text-center max-w-[700px] md:text-[13px] lg:text-[13px] lg:text-justify 2xl:text-[16px] '>Welcome to Chola Engineering, Jaffna's construction leader. With a legacy of excellence, we blend tradition and innovation to redefine standards. Join us in building a brighter future for our community.</p>
            </div>
            <div>
              <h1 data-aos="fade-up" data-aos-duration="2000"  className='text-[40px]  text-accentC uppercase md:text-[64px] lg:text-[92px] 2xl:text-[128px] '>tomorrow</h1>
            </div>
          </div>

          <div className='flex justify-center pt-4 md:hidden'>
            <button className='content_btn '>contact us</button>
          </div>
      </div>

      <div className='hidden sm:block w-full px-[12px] pb-[32px] md:px-[32px] md:pb-[24px] lg:px-[64px] lg:pb-[48px] 2xl:px-[80px] 2xl:pb-[64px] '>
            <ImageSwiper />
      </div>
    </section>
  );
};

export default Hero;