import React from 'react'
import { Backdrop, CircularProgress } from '@mui/material'

const PageLoading = () => {
    return (
        <div>
            <Backdrop open={true}><CircularProgress color='inherit' size={50} thickness={4} /></Backdrop>
        </div>
    )
}

export default PageLoading
