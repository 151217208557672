import React, { useState, useEffect } from 'react';

const ImageSwiper = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const images = [
    {
      src: '/img1_normal.jpg',
      alt: 'Image 1',
      title: 'The Minimalist',
      description: 'Resident of Colombo'
    },
    {
      src: '/img2_normal.jpg',
      alt: 'Image 2',
      title: 'The Largerlist',
      description: 'Resident of Jaffna'
    },
    {
      src: '/img3_normal.jpg',
      alt: 'Image 3',
      title: 'The Smallerlist',
      description: 'Resident of Galle'
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 1500);
    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <section className='flex justify-center mx-auto'>
      <div className='w-full max-w-[1440px] flex gap-[12px] md:gap-[16px] lg:gap-[24px] 2xl:gap-[32px]'>
        {images.map((image, index) => (
          <div 
            key={index} 
            className={`relative transition-all duration-500  ease-in-out  overflow-hidden ${
              activeIndex === index ? 'w-[65%] shadow-lg' : 'w-[35%]'
            } h-[140px] md:h-[166px] lg:h-[240px] 2xl:h-[286px]`}
          >
            <img
              src={image.src}
              alt={image.alt}
              className={`h-full w-full object-cover transition-transform duration-700  ${
                activeIndex === index ? 'scale-110 ' : 'scale-100'
              }`}
            />
            {activeIndex === index && (
              <>
                <div className='absolute inset-0 bg-black opacity-50 transition-opacity duration-700'></div> 
                <button className='absolute transparent_btn bottom-5 right-2 py-1.5         md:bottom-5 md:right-3      lg:bottom-6 lg:right-3 lg:py-2  2xl:bottom-8 2xl:right-6 2xl:py-2 '>contact us</button>
                
                <button className='transparent_btn absolute top-6 right-2 p-1     md:top-7 md:right-3        lg:top-10 lg:right-3 lg:p-2  2xl:top-12 2xl:right-6 2xl:p-3   transform -translate-y-1/2 border text-whiteC rounded-full h'>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-3 h-3 md:w-4 md:h-4 2xl:w-5 2xl:h-5">
                    <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 19.5 15-15m0 0H8.25m11.25 0v11.25" />
                  </svg>
                </button>


                <p className='absolute text-whiteC text-[16px] bottom-8 left-2  lg:text-[24px] lg:bottom-10 lg:left-3   2xl:text-[33px] 2xl:bottom-10 2xl:left-6'>{image.title}</p>
                <p className='absolute text-whiteC text-[10px] bottom-4 left-2  lg:text-[14px] lg:bottom-5  lg:left-3   2xl:text-[15px] 2xl:bottom-5  2xl:left-6 '>{image.description}</p>
              </>
            )}
          </div>
        ))}
      </div>
    </section>
  );
};

export default ImageSwiper;