import React from "react";
import grass from "../../assets/grass.jpeg";
import power from "../../assets/power.jpeg";
import block from "../../assets/block.jpeg";

const products = [
  {
    name: "Block",
    // price: "LKR 15 000.00 / 1 Piece",
    material: "Concrete",
    minQuantity: 100,
    imageUrl: block,
  },
  {
    name: "Grass Paving Blocks",
    // price: "LKR 15 000.00 / 1 Piece",
    material: "Concrete",
    minQuantity: 100,
    imageUrl: grass,
  },
  {
    name: "Paver Blocks",
    // price: "LKR 15 000.00 / 1 Piece",
    material: "Concrete",
    minQuantity: 100,
    imageUrl: power,
  },
];

const ProductGrid = () => {
  return (
    <section className="flex flex-col justify-center items-center mx-auto max-w-[1440px] py-12">
      <div className="px-[12px] py-[64px] md:px-[32px] md:pt-[24px] lg:px-[64px] lg:pt-[48px] 2xl:px-[80px] 2xl:pt-[64px] bg-white grid md:justify-items-end md:text-right justify-items-center text-center">
        <div className='text-primaryC text-[24px] md:text-[32px] lg:text-[48px] 2xl:text-[64px] font-bold uppercase'>
          ELEVATE YOUR PROJECTS
        </div>
        <div className='text-accentC text-[24px] md:text-[32px] lg:text-[48px] 2xl:text-[64px] font-bold uppercase'>
          WITH OUR PRODUCT
        </div>
        <div className="text-[#010465] md:text-[16px] sm:text-[14px] text-[12px] mb-6">
          Enhance your construction endeavors with our extensive range of high-quality construction materials and equipment, carefully curated to empower our projects' success.
        </div>
        <button type="submit" value="send" className="content_btn">
          CONTACT US 
        </button>
        
      </div>

      <div className="flex flex-wrap justify-center w-full px-[12px] pb-[12px]">
        {products.map((product, index) => (
          <div key={index} className="w-full sm:w-1/2 md:w-1/3 p-[12px]">
            <div className="shadow-none">
              <img src={product.imageUrl} alt={product.name} className=" h-96 w-full object-cover"/>
              <div className="flex flex-col items-start p-4">
                <p className="text-[14px] xs:text-[16px] md:text-[19px]  font-medium text-primaryC">
                  {product.name}
                </p>
                <p className="text-gray-500">
                  <span className="text-gray-900">Material:</span> {product.material}
                </p>
                <br />
                <button className="w-full border border-primaryC text-primaryC rounded-full py-4 hover:bg-primaryC hover:text-white">
                  VIEW THE PRODUCT
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="flex justify-center">
        <button className=" content_btn ">VIEW ALL PRODUCTS</button>
      </div>
    </section>
  );
};

export default ProductGrid;
