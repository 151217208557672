import React, { Suspense, lazy } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import PageLoading from './components/PageLoading/PageLoading'
import ProductGrid from './components/ProductGrid/productGrid'

const Home = lazy(() => import('./pages/Home/Home'))
const GetAQuote = lazy(() => import('./pages/GetAQuote/GetAQuote'))
const Collaboration = lazy(() => import('./pages/Collaboration/Collaboration'))
const WhatWeOffer = lazy(() => import('./pages/WhatWeOffer/WhatWeOffer'))
const Services = lazy(() => import('./pages/Services/Services'))
const AboutUs = lazy(() => import('./pages/AboutUs/AboutUs'))
const NotFoundPage = lazy(() => import('./components/NotFoundPage/NotFoundPage'))

const AllRoutes = () => {
  return (
    <BrowserRouter>
    <Suspense fallback={<PageLoading/>}>
    <Routes>
      <Route path='/' element={<Home/>}/>
      <Route path='/getaquote' element={<GetAQuote/>}/>
      <Route path='/collaboration' element={<Collaboration/>}/>
      <Route path='/whatweoffer' element={<WhatWeOffer/>}/>
      {/* <Route path='/careers' element={<Careers/>}/> */}
      <Route path='/aboutus' element={<AboutUs/>}/>
      <Route path='*' element={<NotFoundPage/>}/>
      <Route path='/productGrid' element={<ProductGrid/>}/>
    </Routes>
    </Suspense>
    </BrowserRouter>
  );
};

export default AllRoutes;
